/*
 * Default.scss
 */

* {
    box-sizing: border-box;
    -webkit-text-size-adjust: none;
}

@import url('https://fonts.googleapis.com/css?family=Black+Han+Sans|East+Sea+Dokdo|Hi+Melody|Jua|Nanum+Gothic|Nanum+Gothic+Coding|Noto+Sans+KR|Paytone+One&display=swap&subset=korean');
html {
    height: fit-content;
    width: 100%;
    background-size: cover;
    padding: 0;
}

p, ul, ol, li, dl, dt, dd, form, table, th, td, fieldset, input, textarea, select, button, span, h1, h2, h3, h4, h5, h6, pre, address {
    margin: 0;
    padding: 0;
}

ol, ul, li, dl, dt, dd {
    list-style: none;
}

a {
    color: rgba(255, 255, 255, 1);
}

a:hover {
    color: rgba(255, 255, 255, 0.8);
}

a:link, a:visited {
    text-decoration: none;
}

#body, body {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.9);
    margin: 0;
    padding: 0;
    min-width: 400px;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Arial', 'sans-serif';
    height: fit-content;
    background-size: cover;
}

body::-webkit-scrollbar {
    width: 1em;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid #a0a0a087;
}

text, tspan {
    color: white;
}

/* input number 타입에서 스피너 출력 제거 */
input::-webkit-inner-spin-button, input::-webkit-outer-spin-button, input::-webkit-clear-button {
    -webkit-appearance: none;
    margin: 0;
}

.ck-content .table table td, .ck-content .table table th {
    min-width: 2em;
    padding: .4em;
    border-color: #9a9a9a;
    border: 1px solid #aaa;
}

.imp-dialog {
    z-index: 9999999;
}

/* .user-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
} */